import { AdContainer, AdContainerSize, Block, createComponent, Flex, If, IntrinsicProps } from 'react-commons';
import { App, Layout } from 'react-commons';
import Image from 'next/image';

import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
import FixedAd from '@/components/FixedAd';
import Yolla, { YollaSubType, YollaType } from '@/components/Yolla';
import HideForSWU from '@/components/HideForSWU';

import { TakeoverCampaignData } from '@/lib/drupal/models/Takeover';
import { CaptureClickEventContext } from '@/lib/util/captureClickEvent';

import style from './index.module.scss';

interface ThreeColumnLayoutProps extends IntrinsicProps {
  hideLeaderboardAd?: boolean
  takeover?: TakeoverCampaignData
  pageName?: string
  pageEventSettings?: any
}

export default createComponent<ThreeColumnLayoutProps>('ThreeColumnLayout', { style }, function ThreeColumnLayout ({ className }, props) {
  return (
    <CaptureClickEventContext.Provider value={{
      page: props.pageName,
      settings: props.pageEventSettings || {},
    }}>
      <Layout className={className}>
        <App.Header>
          <AppHeader />
        </App.Header>

        <App.Main>
          {
            If(!!props.takeover, () => (
              <HideForSWU>
                <a href={props.takeover.campaignUrl} rel="noreferrer" target='_blank'>
                  <div className='ThreeColumnLayout__Takeover' style={{ backgroundColor: props.takeover.backgroundColor || 'transparent' }}>
                    <Image
                      src={props.takeover.headerImgSrc}
                      alt={props.takeover.imgAltText}
                      layout='fill'
                      objectFit='cover'
                      sizes="100vw"
                    />
                  </div>
                </a>
              </HideForSWU>
            )).EndIf()
          }

          {
            If(!props.hideLeaderboardAd, () => (
              <HideForSWU>
                <Block>
                  <AdContainer size={AdContainerSize.LEADERBOARD}>
                    <Yolla type={YollaType.DESKTOP_LEADERBOARD} subType={YollaSubType.DESKTOP_LEADERBOARD} />
                  </AdContainer>
                </Block>
              </HideForSWU>
            )).EndIf()
          }

          <Flex justifyCenter className='ThreeColumnLayout__Content'>
            <aside>
              <HideForSWU>
                <AdContainer size={AdContainerSize.MEDIUM_RECT}>
                  <Yolla type={YollaType.DESKTOP_MEDRECT} subType={YollaSubType.DESKTOP_MEDRECT} />
                </AdContainer>
              </HideForSWU>
            </aside>

            <div>
              {props.children}
            </div>

            <aside>
              <HideForSWU>
                <Block>
                  <AdContainer size={AdContainerSize.MEDIUM_RECT}>
                    <Yolla type={YollaType.DESKTOP_MEDRECT} subType={YollaSubType.DESKTOP_MEDRECT} />
                  </AdContainer>
                </Block>
                <Block>
                  <AdContainer size={AdContainerSize.MEDIUM_RECT}>
                    <Yolla type={YollaType.DESKTOP_MEDRECT} subType={YollaSubType.DESKTOP_MEDRECT} />
                  </AdContainer>
                </Block>
              </HideForSWU>
            </aside>
          </Flex>
        </App.Main>

        <HideForSWU>
          <FixedAd />
        </HideForSWU>

        <App.Footer>
          <AppFooter />
        </App.Footer>
      </Layout>
    </CaptureClickEventContext.Provider>
  );
});

export const TwoColumnInner = createComponent('TwoColumnInner', { style }, function TwoColumnInner ({ className, slots }) {
  return (
    <Flex container gap0 collapseMobile>
      <Flex oneQuarter tag='aside'>
        {slots.aside}
      </Flex>
      <Flex fit tag='section'>
        {slots.defaultSlot}
      </Flex>
    </Flex>
  );
});
